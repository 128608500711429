"use strict";

const BOOKING_FORM = function () {
    let submitButton
    let validator
    let form

    // Init form inputs
    const initForm = function () {
        initValidation()
        form.addEventListener('submit', function (e) {
            e.preventDefault()
            submitForm()
        })
    }

    const submitForm = async function () {
        const status = await validator.validate()
        if (status != 'Valid') return Swal.fire({
            text: doLANG("Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: doLANG("Cerrar"),
            customClass: {
                confirmButton: "btn btn-primary"
            }
        })

        try {
            const formData = new FormData(form)
            formData.append('send-booking-form', true)
            formData.append('_ENV_', $_ENV_)

            submitButton.setAttribute("data-kt-indicator", "on")
            submitButton.disabled = true

            const response = await AJAX_DATA({
                method: 'POST',
                url: `${_.AJAX}ajax.site.php`,
                data: formData,
                lock: true
            })

            submitButton.removeAttribute("data-kt-indicator", "on")
            if (!response.error) {
                submitButton.innerText = doLANG('Correo envíado')
                submitButton.classList.remove('btn-primary')
                submitButton.classList.add('btn-secondary')
                return Swal.fire({
                    text: doLANG("El correo se ha envíado correctamente"),
                    icon: "info",
                    buttonsStyling: false,
                    confirmButtonText: doLANG('Cerrar'),
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
            } else {
                Swal.fire({
                    text: doLANG("Hubo un error en el evío") + ".",
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: doLANG('Cerrar'),
                    customClass: {
                        confirmButton: "btn btn-primary"
                    }
                })
                submitButton.disabled = false
                return
            }
        } catch (err) {
            console.error('BOOKING_FORM', err)
            submitButton.removeAttribute("data-kt-indicator", "on")
            submitButton.disabled = false
        }
    }

    const initValidation = function () {
        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        validator = FormValidation.formValidation(
            form,
            {
                fields: {
                    'name': {
                        validators: {
                            notEmpty: {
                                message: doLANG('Este campo es requerido')
                            }
                        }
                    },
                    'email': {
                        validators: {
                            notEmpty: {
                                message: doLANG('Este campo es requerido')
                            },
                            callback: {
                                message: doLANG('Introduzca una dirección de correo electrónico válida'),
                                callback: function (input) {
                                    if (!input.value) return false
                                    const values = JSON.parse(input.value)
                                    for (let i = 0; i < values.length; i++) {
                                        const email = values[i].value
                                        const validation = FormValidation.validators.emailAddress().validate({
                                            value: email,
                                        }).valid
                                        const reg = FormValidation.validators.regexp().validate({
                                            value: email,
                                            options: {
                                                regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$',
                                            },
                                        }).valid
                                        if (!validation || !reg) return false
                                    }

                                    return true
                                },
                            },
                        }
                    },
                    'message': {
                        validators: {
                            notEmpty: {
                                message: doLANG('Este campo es requerido')
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    bootstrap: new FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row',
                        eleInvalidClass: '',
                        eleValidClass: ''
                    })
                }
            }
        )
    }

    return {
        // Public functions
        init: function () {
            // Elements
            form = document.querySelector('#kt_contact_form')
            submitButton = document.getElementById('kt_contact_submit_button')

            initForm()
        }
    }
}()

// On document ready
KTUtil.onDOMContentLoaded(function () {
    BOOKING_FORM.init()
})